<template>
  <div class="page-account">
    <Layout>
      <Header class="title">
        <div style="width:50px;display:flex;align-items:center;"><img style="width:100%;" src="@/assets/images/logo.png" alt=""></div>
        <div style="margin-left:10px">人如云 用技术为百万企业赋能</div>
      </Header>
      <Layout>
        <Content>
          <div class="topHead">
            <span>人如云 管理后台</span>
            <div style="width:100%;margin-left:22%" class="name">—— <span style="color:#FFF">专业商家数智化PAAS服务商</span></div>
          </div>
          <div class="introduce">
            <div class="content">
              <div class="contentTitle">全业态解决方案 总有一款适合你</div>
              <div class="contentVal">餐饮行业、零售便利、母婴行业、烘焙行业、生鲜行业 <br>
                美业行业、宠物行业、服装鞋帽、艺培行业、商贸批发</div>
              <div class="con">
                <img src="@/assets/images/01.jpg">
              </div>
              <div class="con">
                <div class="conTitle">人如云 商家数智化整体解决方案</div>
                <div class="conCon">
                  人如云是安徽互联造物云计算有限公司旗下的知名软件品牌 <br>
基于成熟的产品框架和完整的销售及技术，专注于商家门店私域用户 <br>
数字化智能运营的PaaS平台，截止当前“人如云”已在全国拥有 <br>
20000+注册企业，服务市场遍及300+城市，为全国的商户带去 <br>
来自人如云会员系统解决方案。
                </div>
              </div>
            </div>
          </div>
          <div class="introduce_b">
            <div class="contentdi">
              <div class="ent">
                <div class="entImg">
                  <img src="@/assets/images/2.svg">
                </div>
                <div class="link">
                  <div class="linkTit">《互联造物.电商小程序》</div>
                  <div class="linkCon">代码开源、支持二开、定制、扩展 ，及免费的新版本升级，让企业获得即时优势和行业前沿洞见，助力提高利润、加速发展、进军新市场并颠覆行业格局。</div>
                </div>
              </div>
              <div class="ent">
                <div class="entImg">
                  <img src="@/assets/images/3.svg">
                </div>
                <div class="link">
                  <div class="linkTit">《互联造物.020系统》</div>
                  <div class="linkCon">本地生活外卖跑腿平台 020系统，客户端、骑手端、商家端、员工端、总管理，打造一套完整的020闭环商业系统。</div>
                </div>
              </div>
              <div class="ent">
                <div class="entImg">
                  <img src="@/assets/images/4.svg">
                </div>
                <div class="link">
                  <div class="linkTit">《互联造物.智慧社区》</div>
                  <div class="linkCon">社区一体化数字管理解决方案助力物业推进个性化进程，把握市场界限模糊所带来的机遇。通过数字化转型打造战略性发展，为跨行业奠定基础，最终实现一体化”的数字化智慧社区。</div>
                </div>
              </div>
              <div style="margin:0 24px" class="ent">
                <div class="entImg">
                  <img src="@/assets/images/0003.png">
                </div>
                <div class="link">
                  <div class="linkTit">《互联造物.电子合同》</div>
                  <div class="linkCon">全终端电子合同签署、电子合同批量上传、发起及签署，电子合同在线编辑数据全程可控，身份安全认证、电子合同智能管理</div>
                </div>
              </div>
            </div>
          </div>
          <div class="registered">
            <div class="hasRegistered">已经注册？</div>
            <div id="loginBtn" class="loginBtn" @click="openModel">登录</div>
            <div class="hasRegistered toRegistered">立即注册</div>
            <div class="registeredForm">
              <fieldset>
                <legend class="area">地区</legend>
                <div data-toggle="distpicker">
                  <div class="form-group">
                    <select class="form-control" @change="getProvince" v-model="province">
                      <option v-for="(item,index) in addresData" :key="index" :value="index">{{item.label}}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <select class="form-control" @change="getCity" v-model="city">
                      <option v-for="(item,index) in addresData[province].children" :key="index" :value="index">{{item.label}}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <select class="form-control" @change="getArea" v-model="area">
                      <option v-for="(item,index) in addresData[province].children[city].children" :key="index" :value="index">{{item.label}}</option>
                    </select>
                  </div>
                </div>
              </fieldset>
              <fieldset :class="focus == '2'?'fie':''">
                <legend class="lastName" v-if="focus == '2'" :class="focus == '2'?'fieColor':''">姓名</legend>
                <Input id="lastName" v-model="register.name" class="formRegistered" type="text" placeholder="姓名*" @on-focus="onfocus('2')" @on-blur="focus='0'"/>
              </fieldset>

              <fieldset :class="focus == '3'?'fie':''">
                <legend class="email" v-if="focus == '3'" :class="focus == '3'?'fieColor':''">电子邮件</legend>
                <Input id="email" v-model="register.email" class="formRegistered" type="text" placeholder="电子邮件*" @on-focus="onfocus('3')" @on-blur="focus='0'"/>
              </fieldset>

              <fieldset :class="focus == '4'?'fie':''">
                <legend class="company" v-if="focus == '4'" :class="focus == '4'?'fieColor':''">公司</legend>
                <Input id="company" v-model="register.company" class="formRegistered" type="text" placeholder="公司*" @on-focus="onfocus('4')" @on-blur="focus='0'"/>
              </fieldset>

              <fieldset :class="focus == '5'?'fie':''">
                <legend class="phone" v-if="focus == '5'" :class="focus == '5'?'fieColor':''">电话</legend>
                <Input id="phone" v-model="register.phone" class="formRegistered" type="text" placeholder="电话*" @on-focus="onfocus('5')" @on-blur="focus='0'"/>
              </fieldset>

              <fieldset>
                <legend class="relationship">与互联造物的关系</legend>
                <select id="relationship" v-model="register.relationship"  class="selectpicker">
                  <option role="option" selected="selected" style="display: none"></option>
                  <option value="REL10" role="option">互联造物员工</option>
                  <option value="REL6" role="option">顾问</option>
                  <option value="REL4" role="option">合作伙伴</option>
                  <option value="REL2" role="option">客户</option>
                  <option value="REL7" role="option">媒体/分析师</option>
                  <option value="REL5" role="option">潜在合作伙伴</option>
                  <option value="REL3" role="option">潜在客户</option>
                  <option value="REL9" role="option">实习生</option>
                  <option value="REL8" role="option">投资者/股东</option>
                </select>
              </fieldset>
              <div class="loginBtn" @click="registerSub">提交</div>
            </div>
          </div>
        </Content>
      </Layout>
      <Footer>
        <!-- <div>安徽互联造物云计算有限公司  © 2018-2021</div>
        <div class="dibu"><img src="@/assets/images/ba.png" alt="">&nbsp; 皖ICP备19001043号</div> -->
        <div class="bottom_box">
              <a class="bei_text" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">皖ICP备19001043号-28</a>
              <a class="jin_bei_text" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34019102001024">
                <img style="margin-right:5px" src="../../assets/jh.png" alt="">
                <div>皖公网安备 34019102001024号</div>
              </a>
              <a class="jin_bei_text" target="_blank" :href="config.apiUrl + '/img/yyzz.jpg'">
                <img style="margin-right:5px;width:20px;height:20px" src="../../assets/gh.png" alt="">
                <div>安徽勺子同学科技有限公司</div>
              </a>
              <a class="jin_bei_text" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">
                <img style="margin-right:5px;width:20px;height:20px" src="../../assets/dp.png" alt="">
                <div>经营性 网站备案信息</div>
              </a>
              <a class="jin_bei_text" target="_blank" href="https://www.12315.cn/">
                <div><span style="color:#1A9D3F">12315消费争议</span> 快速解决绿色通道</div>
              </a>
            </div>
      </Footer>
    </Layout>
    <div class="main" v-if="showModel">
      <div class="loginHome">
        <div class="loginTop">
          <span>感谢您对互联造物的关注</span>
          <img id="close" src="@/assets/images/close.png" @click="closeModle">
        </div>
        <div class="loginTit">
          一次注册,永久访问
        </div>
        <div class="loginBody">
          <div class="login-group">
            <Form ref="formInline" :model="formInline" :rules="ruleInline"
                  @keyup.enter="handleSubmit('formInline')">
              <FormItem prop="account">
                <Input type="text" v-model="formInline.account" prefix="ios-contact-outline"
                       placeholder="请输入用户名" size="large"/>
              </FormItem>
              <FormItem prop="password">
                <Input type="password" v-model="formInline.password" prefix="ios-lock-outline"
                       placeholder="请输入密码" size="large"/>
              </FormItem>
              <FormItem>
                <Button class="submitBtn" @click="handleSubmit('formInline')">登录</Button>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
    </div>
    <Modal v-model="modals" scrollable footer-hide closable title="请完成安全校验" :mask-closable="false" :z-index="2"
           width="342">
      <div class="captchaBox">
        <div id="captcha" style="position: relative" ref="captcha"></div>
        <div id="msg"></div>
      </div>
    </Modal>
  </div>
</template>
<script>
import city from '@/utils/city'
import {loginApi } from '@/api/login'
import {registerInfo} from '@/api/register'
import config from "@/config";
import '../../assets/js/canvas-nest.min'
import {setCookies} from "../../utils/helper";
export default {
  data () {
    return {
      focus: '0',
      province: '0',
      city: '0',
      area: '0',
      addresData: city,
      showModel: false,
      modals: false,
      fullWidth: document.documentElement.clientWidth,
      swiperOption: {
        pagination: '.swiper-pagination',
        autoplay: true
      },
      autoLogin: true,
      formInline: {
        account: '',
        password: ''
      },
      ruleInline: {
        account: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      },
      register:{
        province:'北京',
        city:'东城区',
        area:'',
        name:'',
        phone:'',
        email:'',
        company:'',
        relationship:'',
        type:2,
        domain_url:window.location.href,
      }
    }
  },
  created () {
    console.log(config);
    var _this = this
    top !== window && (top.location.href = location.href)
    document.onkeydown = function () {
      if (_this.$route.name === '登录') {
        let key = window.event.keyCode
        if (key === 13) {
          _this.handleSubmit('formInline')
        }
      }
    }
    window.addEventListener('resize', this.handleResize)
  },
  watch: {
    fullWidth (val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val
        this.timer = true
        let that = this
        setTimeout(function () {
          // 打印screenWidth变化的值
          that.timer = false
        }, 400)
      }
    }
  },
  mounted: function () {
  },
  methods: {
    registerSub(){
      registerInfo(this.register).then(res=>{
        this.$Message.success(res.msg)
        this.reset()
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    closeModel(){
        let msg = this.$Message.loading({
          content: '登录中...',
          duration: 0
        })
      loginApi(this.formInline).then(res => {
        msg()
        this.$Message.success(res.msg)
        this.$store.commit('setUserInfo', res.data.user_info)
        this.$store.commit('setMenus', res.data.menus)
        this.$store.commit('setAuth', res.data.unique_auth)
        setCookies('hlzw_saas_token', res.data.token)
        this.$router.push({path: '/admin'})
      }).catch(res => {
        msg()
        this.$Message.error(res.msg)
      })
    },
    reset(){
      this.register.province = '北京'
      this.register.city = '东城区'
      this.register.area = ''
      this.register.name = ''
      this.register.phone = ''
      this.register.email = ''
      this.register.company = ''
      this.register.relationship = ''
      this.province= '0'
      this.city= '0'
      this.area= '0'
    },
    openModel () {
      this.showModel = true
    },
    closeModle () {
      this.showModel = false
    },
    onfocus  (that) {
      this.focus = that;
    },
    getProvince () {
      this.register.province = city[this.province].label
      this.register.city = city[this.province].children[this.city].label
      if (city[this.province].children[this.city].children) {
        this.register.area = city[this.province].children[this.city].children[this.area].label
      }else{
        this.register.area = ''
      }
      this.city = '0';
      this.area = '0';
    },
    getCity () {
      this.register.city = city[this.province].children[this.city].label
      this.register.area = city[this.province].children[this.city].children[this.area].label
      this.area = '0';
    },
    getArea(){
      this.register.area = city[this.province].children[this.city].children[this.area].label
    },
    getExpiresTime (expiresTime) {
      let nowTimeNum = Math.round(new Date() / 1000)
      let expiresTimeNum = expiresTime - nowTimeNum
      return parseFloat(parseFloat(parseFloat(expiresTimeNum / 60) / 60) / 24)
    },
    handleSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.closeModel()
        }
      })
    }
  },
}
</script>
<style scoped>
.fieColor {
  color : #1A73E8;
}
.fie {
  border:1px solid  #1A73E8;
}
.code {
  display: flex;
  align-items: center;
  justify-content: center;
}

.code .pictrue {
  height: 40px;
}
.selectpicker {
  width: 100%;
  border: none;
  outline: none;
}
input[type=text]:focus{
  outline: none;
  border: 1px solid #fff;
}
.loginTop span {
  float: left;
}
.m-b {
  margin-bottom: 15px;
}
.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}
.form-group {
  margin-bottom: 15px;
}
.loginBody {
  width: 100%;
  background: #fff;
}
.login-group {
  margin-top: 50px;
  padding: 0 60px 60px;
  color: #111123;
}
.main {
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.75);
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  color: #FFF;
  text-align: center;
  height:100vh
}
.loginHome {
  width: 600px;
  background: #ffffff;
  display: inline-block;
  margin-top: 100px;
}
.loginTop img {
  width: 20px;
  margin-top: 15px;
}
.loginTit {
  width: 100%;
  height: 100px;
  line-height: 100px;
  background: #008FD3;
  font-size: 30px;
}
.loginTop {
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #000;
  text-align: right;
  font-size: 14px;
  padding: 0 10px;
}
.page-account {
  width: 100%;
  overflow: hidden;
}
.title {
  display:flex;
  height:64px;
  width:100%;
  color: #ffffff;
  font-size: 30px;
  font-weight: bold;
}
.ivu-layout-header,.ivu-layout-footer {
  background-color : #000000;
}
.ivu-layout-header {
  padding: 0 190px;
}
.ivu-layout-footer {
  color:#ffffff;
  text-align :center;
}
.topHead {
  height:340px;
  width:100%;
  background-image: linear-gradient(to right, #222222 , #545454);
  padding-top: 100px;
  padding-left: 190px;
  font-size: 45px;
  color: #ffffff;
}
.name {
  color: #F0AB00;
  margin-left:10px;
}
.introduce {
  width: 100%;
  height: auto;
  background: #F6F6F6;
  padding: 100px 0px;
}
.content {
  width: 50%;
  margin: 0px 40% 0px 10%;
  min-width: 960px;
  display: flow-root;
}
.contentTitle {
  color: #000000;
  font-size: 40px;
  text-align: center;
  width: 60%;
  margin: 0px 20%;
  min-width: 580px;
}
.contentVal {
  color: #000000;
  font-size: 20px;
  text-align: center;
  width: 60%;
  margin: 10px 20%;
  min-width: 580px;
}
.con {
  width: 50%;
  float: left;
  padding: 30px 0;
}
.con img {
  width: 80%;
  margin: 0px 10%;
}
.conTitle {
  font-size: 20px;
}
.conCon {
  line-height: 25px;
  margin-top: 15px;
  font-size: 15px;
}
.introduce_b {
  width: 100%;
  height: auto;
  padding: 0px;
  background: #ffffff;
}
.contentdi {
  width: 100%;
  padding: 0px 0px 0 5%;
  min-width: 960px;
  display: flex;
  box-sizing:border-box
}
.ent {
  width: 30%;
  float: left;
}
.entImg {
  width: 80%;
  margin: 20px 15% 0px;
}
.entImg img {
  width: 40%;
}
.link {
  width: 80%;
  margin: 0px 10% 40px 0px;
}
.linkTit {
  font-size: 18px;
}
.linkCon {
  margin-top: 10px;
  font-size: 14px;
}
.dibu{
  display:flex;
  align-items:center;
  justify-content:center
}
.registered {
  width: 350px;
  height: auto;
  background: #ffffff;
  position: absolute;
  top: 60px;
  right: 80px;
  padding: 40px;
}
.hasRegistered {
  color: #000000;
  font-size: 26px;
}
.loginBtn {
  color: #ffffff;
  background: #007db8;
  width: 80px;
  line-height: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  margin: 20px 0;
  cursor: pointer;
}
.hasRegistered {
  color: #000000;
  font-size: 26px;
}
.form-group {
  margin-bottom: 15px;
}
.form-control{
  background-color: #FFF;
  background-image: none;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  display: block;
  padding: 6px 12px;
  -webkit-transition: border-color .15s ease-in-out 0s,box-shadow .15s ease-in-out 0s;
  transition: border-color .15s ease-in-out 0s,box-shadow .15s ease-in-out 0s;
  width: 100%;
  font-size: 14px;
  height: 34px;
}
.submitBtn {
  width: 100%;
  color: #ffffff;
  background-color: #008FD3;
  height: 40px;
}
fieldset {
  min-width: 0;
  padding: 0.35em .625em 0.35em;
  margin: 0px;
  border: 1px solid silver;
  margin-bottom: 18px;
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
}
legend {
  width: auto;
  border-bottom: 0px;
  font-size: 14px;
  margin-bottom: 0px;
  line-height: inherit;
  color: #333;
  border: 0;
  padding: 0;
  display: block;
}
.check {
  color: blue;
}
.checkReg {
  height: 28px;
}
.formRegistered {
  width: 100%;
  font-size: 14px;
  border: 0px;
  padding-left: 5px;
}
.bottom_box{
  /* position: absolute;
  bottom: 0;
  left: 0; */
  width: 100%;
  background: #0A1622;
  /* height: 60px; */
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
a:hover {
  color: #FE8C00;
}
.bei_text{
  font-size: 12px;
  color: #fff;
}
.jin_bei_text{
  display: flex;
  align-items: center;
  color: #FFF;
  margin-left: 50px;
}
</style>
